<template>
  <v-container fluid fill-height class="login-container">
    <v-col cols="12" lg="12" class="d-flex align-center">
      <v-container>
        <div
          class="d-flex align-center justify-center"
          style="min-height: calc(100vh - 148px)"
        >
          <v-row justify="center">
            <v-col cols="12" md="12">
              <v-card
                elevation="0"
                class="loginBox"
                v-if="this.checkTokenValidity()"
              >
                <v-card elevation="2">
                  <v-toolbar :color="hrdcData.HRDC_BLUE_THEME_COLOR" dark>
                    <span class="text-h6">Reset Password</span>
                  </v-toolbar>
                  <v-card-text class="pb-6 px-6 pt-0">
                    <v-alert
                      dense
                      text
                      type="success"
                      class="mb-4 mt-4"
                      v-if="passwordResetMessage"
                    >
                      {{ passwordResetMessage }}
                    </v-alert>
                    <v-form class="mt-7">
                      <AError :api="api"></AError>

                      <div>
                        <v-label class="text-overline">New Password</v-label>
                        <v-text-field
                          required
                          outlined
                          dense
                          :type="show1 ? 'text' : 'password'"
                          v-model="form.password"
                          @keyup.native.enter="fetch"
                          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                          class="mt-2"
                          @click:append="show1 = !show1"
                          hint="Password must contain at least 8 characters, including uppercase, lowercase, number and special character."
                        ></v-text-field>
                      </div>

                      <div class="">
                        <v-label class="text-overline"
                          >Confirm New Password</v-label
                        >
                        <v-text-field
                          required
                          outlined
                          dense
                          :type="show2 ? 'text' : 'password'"
                          v-model="form.passwordConfirmation"
                          @keyup.native.enter="fetch"
                          class="mt-2"
                          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                          @click:append="show2 = !show2"
                        ></v-text-field>
                      </div>

                      <v-card-actions class="pa-0">
                        <v-btn
                          :loading="api.isLoading"
                          :color="this.hrdcData.HRDC_BLUE_THEME_COLOR"
                          block
                          depressed
                          x-large
                          class="text-decoration-none text-white"
                          @click="fetch()"
                          >Submit</v-btn
                        >
                      </v-card-actions>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-card>
              <v-card elevation="2" class="loginBox" v-else>
                <v-toolbar :color="hrdcData.HRDC_BLUE_THEME_COLOR" dark>
                  <span class="text-h6">Reset Password</span>
                </v-toolbar>
                <v-card-text class="pb-6 px-6 pt-0">
                  <v-alert dense border="left" type="warning" class="mb-4 mt-4">
                    Reset link is invalid or has expired.
                  </v-alert>
                  <v-card-actions class="pa-0">
                    <v-btn
                      :color="hrdcData.HRDC_BLUE_THEME_COLOR"
                      block
                      depressed
                      x-large
                      class="text-decoration-none text-white"
                      @click="cancel()"
                      >Back to Home</v-btn
                    >
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-container>
</template>

<script>
import Api from "@/objects/api";
import HrdcData from "@/services/hrdc/objects/globalData";

export default {
  data: () => ({
    form: {
      password: null,
      passwordConfirmation: null,
      token: null,
    },
    api: new Api(),
    show1: false,
    show2: false,
    isResetLinkValid: true,
    hrdcData: new HrdcData(),
    passwordResetMessage: null,
  }),
  created() {
    this.api.setMethod(`POST`);
    this.api.setUrl(
      `${this.$service.sso}/v1/en/auth/login/password/confirm-reset-password`
    );
    this.api.setParams((this.form.token = this.$route.query.token));
    this.api.setCallbackCompleted((resp) => {
      if (resp.status === "success") {
        this.passwordResetMessage = resp.message;
        this.$router.push({ name: "PageGuestHome" });
      }
    });
  },
  methods: {
    fetch() {
      this.api.setParams(this.form);
      this.api.fetch();
    },
    checkTokenValidity() {
      var token = this.$route.query.token;
      try {
        const decoded = JSON.parse(atob(token));
        const currentTime = new Date().getTime();

        if (currentTime > decoded.exp) {
          return false; // Token has expired
        }
        return true; // Token is valid
      } catch (error) {
        return false; // Token is invalid
      }
    },
    cancel() {
      this.$router.push({ name: "PageGuestHome" });
    },
  },
};
</script>

<style scoped>
.login-title {
  background-image: linear-gradient(
    to right,
    rgba(6, 48, 88),
    rgba(240, 81, 35)
  );
}
.login-container {
  background-color: #fafafb;
  min-height: 100vh; /* Optional: Set minimum height to cover the entire viewport */
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginBox {
  max-width: 475px;
  margin: 0 auto;
}
</style>
